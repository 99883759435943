<template lang="html">
    <entering-form-layout>
        <login-form
            @loginSuccess="onSuccessfulLogin"
            @twoFa="onTwoFa"
        />
    </entering-form-layout>
</template>

<script>
import EnteringFormLayout from '@/layouts/EnteringFormLayout';
import LoginForm from '@/components/login/LoginForm.vue';
import { createNamespacedHelpers } from 'vuex'

// types
import {
    ACTION_UPDATE_WRITER_DATA,
    LOGIN_RESET_MODULES
} from '@/store/modules/writer/action-types.js'

const {
    mapActions: mapWriterActions
} = createNamespacedHelpers('writer')

export default {
    name: 'Login',
    components: {
        EnteringFormLayout,
        LoginForm
    },
    metaInfo: {
        title: 'Login'
    },
    methods: {
        ...mapWriterActions([
            ACTION_UPDATE_WRITER_DATA,
            LOGIN_RESET_MODULES
        ]),
        async onSuccessfulLogin() {
            // we must wait for the statistics to be calculated on the backend after a successful login
            // then we execute this action to pull the updated data and update our frontend store
            await this[ACTION_UPDATE_WRITER_DATA]()
            // Clean vuex store
            await this[LOGIN_RESET_MODULES]()
            // send back to page
            const login_last_location = localStorage.getItem('login_last_location');
            if (login_last_location) {
                localStorage.removeItem('login_last_location')
                window.location.href = login_last_location
                return
            }
            this.$router.push({ name: 'account' })
        },
        onTwoFa() {
            this.$router.push({ name: 'otp' })
        }
    }
}
</script>
