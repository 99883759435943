import {
    required,
    minLength,
    email
} from 'vuelidate/lib/validators';

export const validationRules = {
    email: {
        required,
        email
    },
    password: {
        required,
        minLength: minLength(7)
    },
    code: {
        async isValidCode() {
            if (!this.showOtp) {
                return true
            }
            return this.correctOTP
        }
    }
};
