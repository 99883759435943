<template>
    <div class="authentication__form">
        <!-- signing login section -->
        <div>
            <!-- signing login section -->
            <form
                class="signin-section form-block"
                @submit.prevent="validate"
            >
                <div class="form-block__header">
                    <h2 class="form-block__title">
                        Login
                    </h2>
                </div>

                <div class="form-block__body">
                    <!-- email -->
                    <form-input
                        v-model.trim="emailTrimed"
                        :field.sync="emailTrimed"
                        placeholder="Email address"
                        prepend-icon="EmailOutlineIcon"
                        type="text"
                        :errors="validationMessage($v.email)"
                        :is-valid="$v.email.$dirty && !$v.email.$anyError"
                        @input.native="$v.email.$touch()"
                        @blur.native="$v.email.$touch()"
                    />
                    <!-- email -->
                    <!-- password -->
                    <form-input
                        v-model="password.$model"
                        :field.sync="password"
                        name="password"
                        placeholder="Password"
                        prepend-icon="LockIcon"
                        :append-icon="showPassword ? 'EyeIcon' : 'EyeOffIcon'"
                        :type="showPassword ? 'text' : 'password'"
                        :errors="validationMessage($v.password)"
                        :is-valid="$v.password.$dirty && !$v.password.$anyError"
                        @clicked="showPassword = !showPassword"
                        @input.native="$v.password.$touch()"
                        @blur.native="$v.password.$touch()"
                    />
                    <!-- password -->
                    <!-- OTP -->
                    <form-input
                        v-if="showOtp"
                        v-model="$v.code.$model"
                        outlined
                        :field.sync="code"
                        legend="Code"
                        placeholder="123 456"
                        :errors="validationMessage($v.code)"
                        :is-valid="$v.code.$dirty && !$v.code.$anyError"
                        @input.native="$v.code.$touch()"
                        @blur.native="$v.code.$touch()"
                    />
                    <!-- OTP -->
                    <p class="form-block__description">
                        Forgot your password?

                        <!-- forgot password link -->
                        <router-link
                            class="colored-text"
                            :to="{ name: 'forgot-password', params: {} }"
                        >
                            Reset
                        </router-link>
                        <!-- forgot password link -->
                        or
                        <router-link
                            class="colored-text"
                            :to="{
                                name: 'register',
                                params: {}
                            }"
                        >
                            Register an Account
                        </router-link>
                    </p>

                    <!-- remember me -->
                    <div class="form-block__checkbox">
                        <div class="form-block__checkbox--container">
                            <form-checkbox
                                v-model="chk_remember"
                                check="login"
                            />
                            <span>Remember me on this computer</span>
                        </div>
                    </div>
                    <!-- remember me -->

                    <!-- submit -->
                    <custom-button
                        :loading="loading"
                        class="btn-base btn-main button-width-100"
                    >
                        Sign In
                    </custom-button>
                    <!-- submit -->

                    <!-- social login -->
                    <!-- <social-login mode="login" /> -->
                    <!-- social login -->
                </div>

                <div class="form-block__footer" />
            </form>
            <!-- signing login section -->
        </div>
    <!-- signing login section -->
    </div>
</template>

<script>
// validation
import { validationMixin } from 'vuelidate';
import formValidationMixin from '@/mixins/formValidationMixin';
import { validationMessage } from 'vuelidate-messages';
import { validationRules } from '@/validation/login/Rules';
import { formMessages } from '@/validation/login/Messages';

// import SocialLogin from '@/components/common/SocialLogin.vue';
import { eventBus } from '@/helpers/event-bus/'

import {
    ACTION_LOGIN_WRITER
} from '@/store/modules/writer/action-types';

import { createNamespacedHelpers } from 'vuex';

const { mapActions: mapWriterActions } = createNamespacedHelpers('writer');

export default {
    name: 'LoginForm',
    components: {
        // SocialLogin
    },
    mixins: [
        validationMixin,
        formValidationMixin
    ],
    validations: {
        ...validationRules
    },
    data: () => ({
        email: '',
        emailTrimed: '',
        password: '',
        showPassword: false,
        loading: false,
        chk_remember: false,
        // otp
        showOtp: false,
        correctOTP: true,
        code: null
    }),
    watch: {
        emailTrimed(value) {
            this.email = value.trim()
        },
        code() {
            if (!this.correctOTP) {
                this.correctOTP = true
                this.$nextTick(() => {
                    this.$v.code.$reset()
                })
            }
        }
    },
    methods: {
        ...mapWriterActions([
            ACTION_LOGIN_WRITER
        ]),
        validationMessage: validationMessage(formMessages),
        async submit() {
            const vm = this
            try {
                vm.loading = true
                await this[ACTION_LOGIN_WRITER]({
                    email: this.email.trim(),
                    password: this.password,
                    remember_me: this.chk_remember,
                    one_time_password: this.code
                })
                this.$emit('loginSuccess')
            } catch (e) {
                // desired loader behavior: if login is successful, loader keeps going until redirect complete
                if (e === 'OTP is required' && !this.showOtp) {
                    this.showOtp = true
                    vm.loading = false
                    // this.$emit('twoFa')
                    return
                }
                if (e === 'Wrong OTP' || (e === 'OTP is required' && this.showOtp)) {
                    this.correctOTP = false
                    vm.loading = false
                    return
                }
                vm.loading = false
                eventBus.$emit('showSnackBar', e, 'error')
            }
        }
    }
}
</script>
